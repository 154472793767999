<template>
  <div class="about">
    <div v-if="!isSupported">您当前的浏览器不支持直播</div>
    
    <!-- <div id="pk-wrap" v-if="isStartClient == 1">
      <van-loading size="200px" vertical>加载中...</van-loading>
    </div> -->
    <!-- 主播画面 -->
    <div id="local_video" style="width: 100%; height: 100vh"></div>
    <!-- 连麦时连麦人画面(trtc) -->
    <!-- <div id="remote_stream"></div> -->
    <!-- 连麦时连麦人画面(tcplayer) -->
    <video
      v-show="isStartClient == 2"
      id="player-container-id"
      style="
        width: 200px;
        height: 200px;
        position: fixed;
        left: 5px;
        top: 100px;
      "
      preload="auto"
      playsinline
      webkit-playsinline
      x5-playsinline
    ></video>
    <!-- <div id=""></div> -->
    <!-- <div style="position: fixed; top: 200px; left: 30px; z-index: 9999">
      <van-button @click="initPullUrl" type="success">混流</van-button>
    </div> -->
  </div>
</template>

<script>
import {
  getPushUrl,
  mixStreamPost,
  getPullUrl,
  systemInfoPost,
} from "@/api/live-api";
import { CDNURL } from "@/config/config.js";
const livePusher = new TXLivePusher();
import { genTestUserSig } from "@/debug/GenerateTestUserSig.js";
import TCPlayer from "tcplayer.js";
export default {
  data() {
    return {
      isSupported: true,
      cameraDeviceId: null,

      pushQuery: {
        id: "", // 房间id
        userId: "", //主播id
        role: "anchor",
        type: "webrtc",
      },
      client: null,
      userId: "",
      isStartClient: 0, // 是否开启连麦 0未开启或已拒绝 1等待中 2已同意
      mixConfig: {
        mode: "manual",
        videoWidth: 750,
        videoHeight: 1000,
        videoBitrate: 1500,
        videoFramerate: 15,
        mixUsers: [],
      },
      audienceId: "15214206275", // 连麦人ID
      localStream: {},

      context: null,
      configInfo: {},
    };
  },
  // watch: {
  //   $route: {
  //     handler(val) {
  //       this.isStartClient = val.query.isStartClient;

  //       if (this.isStartClient == 2) {
  //         uni.postMessage({
  //           data: {
  //             errmsg: "监听到观众申请连麦",
  //             code: 200,
  //           },
  //         });
  //         // this.initTrtc(val.query.audienceId);
  //         this.$nextTick(() => {
  //           this.initPullUrl(val.query.audienceId);
  //         });
  //       } else if (this.isStartClient == 1) {
  //         try {
  //           // this.context.dispose();
  //         } catch (error) {}
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  created() {},
  mounted() {
    // // 设备管理
    // const deviceManager = livePusher.getDeviceManager();
    // // 获取设备列表
    // deviceManager.getDevicesList().then( (data) => {
    //   console.log('管理设备', data);
    //   data.forEach( (device) => {
    //     console.log(device.type, device.deviceId, device.deviceName);
    //     if (device.type === "video") {
    //       this.cameraDeviceId = device.deviceId;
    //     }
    //   });

    //   // 切换摄像头设备
    //   if (this.cameraDeviceId) {
    //     deviceManager.switchCamera(this.cameraDeviceId);
    //   }
    // }).catch(err => {
    //   console.log('管理设备错误', err);
    // });

    livePusher.setObserver({
      // 推流连接状态
      onPushStatusUpdate: (status, message) => {
        uni.postMessage({
          data: {
            msg: message,
          },
        });
        console.warn("推流连接状态");
        console.log(status, message);
      },
      // 推流统计数据
      onStatisticsUpdate: (data) => {
        console.warn("推流统计数据");
        // uni.postMessage({
        //   data: {
        //     msg: data.video.framesPerSecond,
        //   },
        // });
        // console.log("video fps is " + data.video.framesPerSecond);
      },
    });
    this.pushQuery.id = this.getUrlValue("liveId");
    this.pushQuery.userId = this.getUrlValue("userId");
    this.userId = this.getUrlValue("userId");
    // 房间id
    // this.pushQuery.id = "2112241996";
    // this.pushQuery.userId = "17640101769";
    // this.userId = "17640101769";
    console.log(this.pushQuery.id, this.pushQuery.userId);
    this.initPushUrl();
  },
  destroyed() {
    // 停止推流
    livePusher.stopPush();
    // 关闭摄像头
    livePusher.stopCamera();
    // 关闭麦克风
    livePusher.stopMicrophone();
  },
  methods: {
    // 获取推流地址
    async initPushUrl() {
      const res = await getPushUrl(this.pushQuery);
      this.configInfo = await systemInfoPost();
      // sessionScreen 1:480p 2:720p 3:1080p 4:2k 5:4k
      console.log("推流地址", res);
      this.startLive(res);
    },
    startLive(url) {
      TXLivePusher.checkSupport().then((data) => {
        // 是否支持WebRTC
        if (data.isWebRTCSupported) {
          this.isSupported = true;
          console.log("WebRTC Support");
        } else {
          this.isSupported = false;
          console.log("WebRTC Not Support");
        }

        // 是否支持H264编码
        if (data.isH264EncodeSupported) {
          console.log("H264 Encode Support");
          this.isSupported = true;

          console.log("livePusher", livePusher);

          livePusher.setRenderView("local_video");
          livePusher.videoView.muted = true;
          livePusher.videoView.objectFit = "fill";
          if (this.configInfo.sessionScreen == 1) {
            // 设置视频质量
            livePusher.setVideoQuality("480p");
            // 设置分辨率
            livePusher.setProperty("setVideoResolution", {
              width: 640,
              height: 480,
            });
            // 设置音频质量
            // livePusher.setAudioQuality("standard");
            livePusher.setProperty("setVideoBitrate", 900);
          } else if (this.configInfo.sessionScreen == 2) {
            livePusher.setVideoQuality("720p");
            livePusher.setProperty("setVideoResolution", {
              width: 1280,
              height: 720,
            });
            // livePusher.setAudioQuality("standard");
            livePusher.setProperty("setVideoBitrate", 1500);
          } else if (this.configInfo.sessionScreen == 3) {
            livePusher.setVideoQuality("1080p");
            livePusher.setProperty("setVideoResolution", {
              width: 1920,
              height: 1080,
            });
            // livePusher.setAudioQuality("standard");
            livePusher.setProperty("setVideoBitrate", 2000);
          }else if (this.configInfo.sessionScreen == 4) {
            livePusher.setVideoQuality("2K");
            livePusher.setProperty("setVideoResolution", {
              width: 2560,
              height: 1440,
            });
            // livePusher.setAudioQuality("standard");
            // livePusher.setProperty("setVideoBitrate", 2000);
          }else if (this.configInfo.sessionScreen == 5) {
            livePusher.setVideoQuality("4K");
            livePusher.setProperty("setVideoResolution", {
              width: 3840,
              height: 2160,
            });
            // livePusher.setAudioQuality("standard");
            // livePusher.setProperty("setVideoBitrate", 2000);
          }
          // 自定义设置帧率
          livePusher.setProperty("setVideoFPS", 15);

          // 采集完摄像头和麦克风之后自动推流
          Promise.all([
            livePusher.startCamera(),
            livePusher.startMicrophone(),
            // livePusher.startScreenCapture({ audio: true }),
          ])
            .then((res) => {
              // this.$toast(res[2]);
              uni.postMessage({
                data: {
                  msg: "开启直播",
                },
              });
              livePusher.startPush(url);

              // livePusher.startPush('webrtc://184331.push.tlivecloud.com/live/anchor_1688751695521693698_1665954160288321537?txSecret=f8dbf03585000a694bfb30243c42a87f&txTime=64D36447');
            })
            .catch((err) => {
              console.log(err); // 没找到设备
            });
        } else {
          console.log("H264 Encode Not Support");
          this.isSupported = false;
        }
      });
    },

    async initPullUrl(audienceId) {
      this.context = TCPlayer("player-container-id", {
        autoplay: true,
        preload: "auto",
        controls: false,
        webrtcConfig: {
          connectRetryCount: 3,
          connectRetryDelay: 1,
          showLog: true,
        },
      }); // player-container-id 为播放器容器 ID，必须与 html 中一致
      this.context.ready(async () => {
        const res = await getPullUrl({
          role: "anchor", //后续不需要传此参数
          isWebrtc: true,
          uid: audienceId,
          id: this.pushQuery.id,
        });
        this.context.src(res); // url 播放地址
        uni.postMessage({
          data: {
            msg: "连麦播放地址",
            code: 200,
            res,
          },
        });
      });
      this.context.on("error", (error) => {
        // 做一些处理
        console.warn("错误信息", error);
        uni.postMessage({
          data: {
            errmsg: "当前直播已结束",
            code: 500,
          },
        });
      });
      // this.context.src('http://zb.huiris.com/live/anchor_1688751695521693698_1665954160288321537.flv'); // url 播放地址

      // console.warn("开始观看", res);
      // const cdnUrl = `http://${CDNURL}/live/user_stream_${this.pullQuery.uid}.m3u8`;
      // const cdnUrl = `http://${CDNURL}/live/1400811816_${+this.pullQuery.id.slice(0, 4)}_${this.pullQuery.uid}.m3u8`
      // const cdnUrl = `http://zb.huiris.com.txlivecdn.com/live/1400811816_${+this.pullQuery.id.slice(0, 4)}_${this.pullQuery.uid}_main.m3u8`
      // console.log('cdnUrl', cdnUrl);
      // this.context.src(cdnUrl); // url 播放地址
    },

    // 创建监听
    onRemoteVideo() {
      this.$trtc.on(
        this.$TRTC.EVENT.REMOTE_VIDEO_AVAILABLE,
        ({ userId, streamType }) => {
          // 为了播放视频画面，您需在 DOM 中放置一个 HTMLElement，可以是一个 div 标签，假设其 id 为 `${userId}_${streamType}`
          this.startMixStream();
        }
      );
    },
    // 初始化TRTC
    initTrtc(audienceId) {
      // 停止云直播推流
      // livePusher.stopPush();
      this.$toast("主播开启混流");
      // this.audienceId = audienceId;
      this.client = this.$trtc.createClient({
        mode: "live",
        sdkAppId: 1400811816,
        userId: this.userId,
        userSig: genTestUserSig(this.userId).userSig,
      });
      this.localStream = this.$trtc.createStream({
        userId: this.userId,
        audio: true,
        video: true,
      });
      this.joinRoom();
      this.client.on("stream-added", async (event) => {
        const remoteStream = event.stream;
        // 订阅远端音频和视频流
        this.client
          .subscribe(remoteStream, { audio: true, video: true })
          .catch((e) => {
            console.error("failed to subscribe remoteStream");
            if (e.getCode() === 16461) {
              // API_CALL_ABORTED 订阅过程中，远端取消了推流导致订阅中断，无需特殊处理。
            }
            // 其他异常，例如因网络问题导致连接超时。
          });
        // this.localStream.play("local_stream");
        const streamId = encodeURIComponent(
          `1400811816_${+this.pushQuery.id.slice(0, 4)}_${this.userId}`
        );
        const res = await mixStreamPost({
          sdkAppId: 1400811816,
          roomId: +this.pushQuery.id.slice(0, 4),

          streamId,
          videoWidth: 800,
          videoHeight: 1000,
          videoBitrate: 512,
          videoFramerate: 15,
          videoGop: 2,
          audioSampleRate: 48000,
          audioBitrate: 64,
          audioChannels: 2,
          mainVideoUserId: this.userId,
          // littleVideoUserId: this.audienceId,
          littleVideoUserId: "15214206275",
          imageWidth: 200,
          imageHeight: 200,
          locationX: 20,
          locationY: 20,
        });
        console.warn("混流接口返回", res);
        this.$toast(res);
        // livePusher.stopPush();
        // const cdnUrl = encodeURIComponent(
        //   `http://${CDNURL}/live/1400811816_${+this.pushQuery.id.slice(0, 4)}_${this.userId}_main.flv`
        // );
        const cdnUrl = `http://${CDNURL}/live/1400811816_${+this.pushQuery.id.slice(
          0,
          4
        )}_${this.userId}_main.flv`;
        console.warn("cdnUrl", cdnUrl);
        // livePusher.startPush(cdnUrl);
      });
      // 监听订阅成功事件并播放远端流
      this.client.on("stream-subscribed", (event) => {
        console.warn("event", event);
        const remoteStream = event.stream;
        console.warn("remoteStream", remoteStream);
        // 远端流订阅成功，播放远端音视频流
        remoteStream.play("remote_stream");
        // this.isStartClient = 2;
        // const remoteStream = event.stream;
        // console.log("远端流订阅成功：" + remoteStream.getId());
        // remoteStream.on("error", (error) => {
        //   const errorCode = error.getCode();
        //   if (errorCode === 0x4043) {
        //     // PLAY_NOT_ALLOWED, 引导用户手势操作并调用 stream.resume 恢复音视频播放
        //     // remoteStream.resume()
        //     this.$toast("引导用户手势操作并调用");
        //   }
        // });
        // this.startMixStream();
      });
    },
    // async startMixStream() {
    // livePusher.stopPush();
    // // 全手动模式 自 v4.8.0 版本开始支持
    // try {
    //   this.mixConfig.mixUsers = [
    //     {
    //       userId: this.userId,
    //       roomId: +this.pushQuery.id.slice(0, 4), // roomId 字段自 v4.11.5 版本开始支持，支持跨房间混流
    //       pureAudio: false,
    //       width: 300,
    //       height: 480,
    //       locationX: 0,
    //       locationY: 0,
    //       streamType: "main", // 指明该配置为远端主流
    //       zOrder: 1,
    //     },
    //     {
    //       userId: this.audienceId,
    //       roomId: +this.pushQuery.id.slice(0, 4), // roomId 字段自 v4.11.5 版本开始支持，支持跨房间混流
    //       pureAudio: false,
    //       width: 100,
    //       height: 100,
    //       locationX: 0,
    //       locationY: 0,
    //       streamType: "auxiliary", // 指明该配置为远端主流
    //       zOrder: 1,
    //     },
    //   ];
    //   await this.client.startMixTranscode(this.mixConfig);
    // } catch (error) {
    //   console.error("startMixTranscode failed: ", error);
    // }
    // this.$toast("主播端转换流");
    // const streamId = encodeURIComponent(
    //   `1400811816_${+this.pushQuery.id.slice(0, 4)}_${this.userId}_main.flv`
    // );
    // this.$toast("streamId");
    // console.warn("cdnurl地址", streamId);
    // livePusher.startPush(streamId);
    // `http://sdwh.huiris.com/live/${streamId}.flv`
    // },

    // async joinRoom() {
    //   try {
    //     this.client.startPublishCDNStream({ streamType: "main", streamId: `user_stream_${this.userId}` });
    //     await this.client.join({
    //       roomId: +this.pushQuery.id.slice(0, 4),
    //       role: "anchor",

    //     });
    //     this.$toast("进房成功");
    //     console.log("进房成功");
    //   } catch (error) {
    //     this.$toast("进房失败，请稍后再试");
    //     console.error("进房失败，请稍后再试" + error);
    //   }
    //   try {
    //     await this.localStream.initialize();
    //     // this.localStream.play("local_stream");
    //     this.$toast("初始化本地流成功");
    //     console.log("初始化本地流成功");
    //   } catch (error) {
    //     this.$toast("初始化本地流失败");
    //     console.error("初始化本地流失败 " + error);
    //   }
    //   try {
    //     await this.client.publish(this.localStream);
    //     this.$toast("本地流发布成功");
    //     console.log("本地流发布成功");
    //   } catch (error) {
    //     this.$toast("本地流发布失败");
    //     console.error("本地流发布失败 " + error);
    //   }
    // },

    getUrlValue(value) {
      // 从第一个?开始，且不包括#之后，并截取掉?的部分
      var query = location.search.substring(1);
      // 从#开始的部分，并转换成数组
      var hash = location.hash.split("?");
      // query和hash均没有参数
      if (!query && hash.length < 2) {
        return "";
      }
      // 先取query部分的参数进行匹配
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == value) {
          return pair[1];
        }
      }
      // query没有参数，或者有参数但没找到，则取hash部分的参数
      if (!hash[1]) {
        return "";
      }
      vars = hash[1].split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == value) {
          return pair[1];
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
#local_video {
  display: flex;
  align-items: flex-end;
  &::v-deep {
    video {
      // height: 100vh !important;
      // border: 1px solid blue !important;
      height: 100% !important;
      flex: 1;
      object-fit: cover !important;
    }
  }
}

#pk-wrap {
  position: fixed;
  right: 30px;
  top: 60px;
  z-index: 99;
  width: 200px;
  height: 200px;
}
#remote_stream {
  position: fixed;
  right: 30px;
  bottom: 60px;
  z-index: 99999;
  width: 200px;
  height: 200px;
}
</style>
