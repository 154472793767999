<template>
  <div class="home">
    <div class="notice-wrap">
      <van-notice-bar
        left-icon="volume-o"
        scrollable
        text="在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。"
      />
    </div>
    <!-- muted -->
    <!-- playsinline
      webkit-playsinline
      x5-playsinline
     -->
    <video
      id="player-container-id"
      style="width: 100%; height: 100%"
      preload="auto"
    ></video>

    <div
      v-show="isStartClient == 2"
      style="position: fixed; top: 20px; right: 20px"
    >
      <div class="end-btn" @click="endingHandle">结束连麦</div>
    </div>
    <!-- <div id="local_stream" v-if="isStartClient == 2"></div> -->
    <div class="local_stream_wrap" v-show="isStartClient == 2">
      <div id="local_stream"></div>
    </div>
    <!-- <div id="local_video"></div> -->

    <!-- <div style="position: fixed; bottom: 100px; left: 30px; z-index: 9999">
      <van-button @click="initPushUrl" type="success">连麦</van-button>
    </div> -->
  </div>
</template>

<script>
const livePusher = new TXLivePusher();
import TCPlayer from "tcplayer.js";
import { getPullUrl, mixStreamPost, getPushUrl, systemInfoPost } from "@/api/live-api";
import { genTestUserSig } from "@/debug/GenerateTestUserSig.js";
// import { Toast, Button } from "vant";
import { CDNURL } from "@/config/config.js";
// @ is an alias to /src

// function init(num) {
//   Toast(num);
// }

export default {
  components: {},
  data() {
    return {
      isSupported: true,
      cameraDeviceId: null,
      context: null,
      pullQuery: {
        id: "",
        uid: "", //后续不需要传此参数
        role: "", //后续不需要传此参数
        isWebrtc: true,
      },
      client: null,
      userId: "",
      localStream: {},
      isStartClient: 0, // 是否开启连麦 0未开启或已拒绝 1等待中 2已同意
      configInfo: {},
    };
  },
  watch: {
    $route: {
      handler(val) {
        console.log(val.query);
        this.isStartClient = val.query.isStartClient;
        if (this.isStartClient == 2) {
          // this.initTrtc();
          this.initPushUrl();
        } else if (this.isStartClient == 1) {
          this.endingHandlePop();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.pullQuery.id = this.getUrlValue("liveId");
    this.pullQuery.uid = this.getUrlValue("anchorId");
    this.pullQuery.role = this.getUrlValue("role");
    this.userId = this.getUrlValue("userId");
    // this.pullQuery.id = "2112241996";
    // this.pullQuery.uid = "17640101769";
    // this.userId = "15214206275";
    this.context = TCPlayer("player-container-id", {
      autoplay: true,
      preload: "auto",
      controls: true,
      controlBar: {
        QualitySwitcherMenuButton: true,
        fullscreenToggle: true,
        volumePanel: true,
        playToggle: true,
      },
      webrtcConfig: {
        connectRetryCount: 3,
        connectRetryDelay: 1,
        showLog: true,
      },
    }); // player-container-id 为播放器容器 ID，必须与 html 中一致
    this.context.ready(() => {
      this.initPullUrl();
    });
    this.context.on("error", (error) => {
      // 做一些处理
      console.warn("错误信息", error);
      uni.postMessage({
        data: {
          errmsg: "当前直播已结束",
          code: 500,
        },
      });
    });
    this.context.on("fullscreenchange", (res) => {
      // 做一些处理
      uni.postMessage({
        data: {
          res,
          code: 200,
        },
      });
    });
  },
  methods: {
    // 结束连麦
    endingHandle() {
      this.$dialog
        .confirm({
          title: "结束连麦",
          message: "是否确认结束连麦?",
        })
        .then(() => {
          this.endingHandlePop();
        });
    },
    endingHandlePop() {
      // 停止推流
      livePusher.stopPush();
      // 关闭摄像头
      livePusher.stopCamera();
      // 关闭麦克风
      livePusher.stopMicrophone();
      this.isStartClient = 1;
      uni.postMessage({
        data: {
          msg: "结束连麦",
        },
      });
    },
    // 初始化TRTC
    // async initTrtc() {
    // // 互动直播模式下创建客户端对象
    //   this.client = this.$trtc.createClient({
    //     mode: "live",
    //     sdkAppId: 1400811816,
    //     userId: this.userId,
    //     userSig: genTestUserSig(this.userId).userSig,
    //   });
    //   this.localStream = this.$trtc.createStream({
    //     userId: this.userId,
    //     audio: true,
    //     video: true,
    //   });
    //   try {
    //     console.warn("房间id", +this.pullQuery.id.slice(0, 4));
    //     await this.client.join({ roomId: +this.pullQuery.id.slice(0, 4) });
    //     this.$toast("进房成功");
    //     console.log("进房成功");
    //   } catch (error) {
    //     this.$toast("进房失败，请稍后再试");
    //     console.error("进房失败，请稍后再试" + error);
    //   }

    //   try {
    //     await this.localStream.initialize();
    //     this.localStream.play("local_stream");
    //     this.$toast("初始化本地流成功");
    //     console.log("初始化本地流成功");
    //   } catch (error) {
    //     this.$toast("初始化本地流失败");
    //     console.error("初始化本地流失败 " + error);
    //   }
    //   try {
    //     await this.client.publish(this.localStream);
    //     this.$toast("本地流发布成功");
    //     console.log("本地流发布成功");
    //     this.client.startPublishCDNStream({
    //       streamType: "auxiliary",
    //       streamId: `user_stream_${this.pullQuery.uid}`
    //     });
    //   } catch (error) {
    //     this.$toast("本地流发布失败");
    //     console.error("本地流发布失败 " + error);
    //   }
    // },
    // 连麦时观众发起腾讯云直播
    // 获取推流地址
    async initPushUrl() {
      const res = await getPushUrl({
        id: this.pullQuery.id,
        userId: this.userId,
        role: "audience",
        type: "webrtc",
      });
      this.configInfo = await systemInfoPost();
      // sessionScreen 1:480p 2:720p 3:1080p 4:2k 5:4k
      console.log("推流地址", res);
      this.startLive(res);
    },
    startLive(url) {
      TXLivePusher.checkSupport().then((data) => {
        // 是否支持WebRTC
        if (data.isWebRTCSupported) {
          this.isSupported = true;
          console.log("WebRTC Support");
        } else {
          this.isSupported = false;
          console.log("WebRTC Not Support");
        }

        // 是否支持H264编码
        if (data.isH264EncodeSupported) {
          console.log("H264 Encode Support");
          this.isSupported = true;

          console.log("livePusher", livePusher);

          if (this.configInfo.sessionScreen == 1) {
            // 设置视频质量
            livePusher.setVideoQuality("480p");
            // 设置分辨率
            livePusher.setProperty("setVideoResolution", {
              width: 640,
              height: 480,
            });
            // 设置音频质量
            // livePusher.setAudioQuality("standard");
            livePusher.setProperty("setVideoBitrate", 900);
          } else if (this.configInfo.sessionScreen == 2) {
            livePusher.setVideoQuality("720p");
            livePusher.setProperty("setVideoResolution", {
              width: 1280,
              height: 720,
            });
            // livePusher.setAudioQuality("standard");
            livePusher.setProperty("setVideoBitrate", 1500);
          } else if (this.configInfo.sessionScreen == 3) {
            livePusher.setVideoQuality("1080p");
            livePusher.setProperty("setVideoResolution", {
              width: 1920,
              height: 1080,
            });
            // livePusher.setAudioQuality("standard");
            livePusher.setProperty("setVideoBitrate", 2000);
          }
          else if (this.configInfo.sessionScreen == 4) {
            livePusher.setVideoQuality("2K");
            livePusher.setProperty("setVideoResolution", {
              width: 1560,
              height: 1440,
            });
            // livePusher.setAudioQuality("standard");
            // livePusher.setProperty("setVideoBitrate", 2000);
          }
          else if (this.configInfo.sessionScreen == 5) {
            livePusher.setVideoQuality("4K");
            livePusher.setProperty("setVideoResolution", {
              width: 3840,
              height: 2160,
            });
            // livePusher.setAudioQuality("standard");
            // livePusher.setProperty("setVideoBitrate", 2000);
          }
          // 自定义设置帧率
          livePusher.setProperty("setVideoFPS", 15);

          // livePusher.setRenderView("local_stream");
          // livePusher.videoView.muted = true;
          // livePusher.videoView.objectFit = "fill";
          // 设置视频质量
          // livePusher.setVideoQuality("1080p");
          // 设置音频质量
          // livePusher.setAudioQuality("standard");
          // 自定义设置帧率
          // livePusher.setProperty("setVideoFPS", 25);
          // 设置分辨率
          // livePusher.setProperty('setVideoResolution', { width: 1920, height: 1080 });

          // 采集完摄像头和麦克风之后自动推流
          Promise.all([
            livePusher.startCamera(),
            livePusher.startMicrophone(),
            // livePusher.startScreenCapture({ audio: true }),
          ])
            .then((res) => {
              // this.$toast(res[2]);
              uni.postMessage({
                data: {
                  msg: "开启直播",
                },
              });
              livePusher.startPush(url);
              // livePusher.startPush('webrtc://184331.push.tlivecloud.com/live/anchor_1688751695521693698_1665954160288321537?txSecret=f8dbf03585000a694bfb30243c42a87f&txTime=64D36447');
            })
            .catch((err) => {
              console.log(err); // 没找到设备
            });
        } else {
          console.log("H264 Encode Not Support");
          this.isSupported = false;
        }
      });
    },
    async initPullUrl() {
      // this.context.src('http://zb.huiris.com/live/anchor_1688751695521693698_1665954160288321537.flv'); // url 播放地址
      const res = await getPullUrl(this.pullQuery);
      console.warn("开始观看", res);

      // const cdnUrl = `http://${CDNURL}/live/user_stream_${this.pullQuery.uid}.m3u8`;
      // const cdnUrl = `http://${CDNURL}/live/1400811816_${+this.pullQuery.id.slice(0, 4)}_${this.pullQuery.uid}.m3u8`
      // const cdnUrl = `http://zb.huiris.com.txlivecdn.com/live/1400811816_${+this.pullQuery.id.slice(0, 4)}_${this.pullQuery.uid}_main.m3u8`
      // console.log('cdnUrl', cdnUrl);
      this.context.src(res); // url 播放地址
      // this.context.requestFullscreen()
      uni.postMessage({
        data: {
          msg: res,
        },
      });
      // this.context.src(cdnUrl); // url 播放地址
    },

    getUrlValue(value) {
      // 从第一个?开始，且不包括#之后，并截取掉?的部分
      var query = location.search.substring(1);
      // 从#开始的部分，并转换成数组
      var hash = location.hash.split("?");
      // query和hash均没有参数
      if (!query && hash.length < 2) {
        return "";
      }
      // 先取query部分的参数进行匹配
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == value) {
          return pair[1];
        }
      }
      // query没有参数，或者有参数但没找到，则取hash部分的参数
      if (!hash[1]) {
        return "";
      }
      vars = hash[1].split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == value) {
          return pair[1];
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
}
.home {
  width: 100vw;
  height: 100vh;
}
#local_video {
  position: fixed;
  right: 30px;
  top: 30px;
  &::v-deep {
    video {
      // height: 100vh !important;
      // border: 1px solid blue !important;
      width: 200px !important;
      height: 200px !important;
      flex: 1;
      object-fit: cover !important;
    }
  }
}
#local_stream {
  width: 150px;
  height: 150px;
}
.local_stream_wrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
.end-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 80px;
  height: 30px;
  background-color: #ff7a03;
  border-radius: 30px;
  z-index: 99;
  color: #fff;
}
</style>
